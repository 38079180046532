// Types
import { ChangeEvent } from 'react';
import { MemorabiliaSaleMethod } from 'api/graphql-global-types';

// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Components
import CheckboxFilter from '../CheckboxFilter/CheckboxFilter';

type SaleMethodFilterProps = {
  availableOptions: MemorabiliaSaleMethod[];
};

export const SaleMethodFilter = ({
  availableOptions,
}: SaleMethodFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name: MemorabiliaSaleMethod = e.target.name as MemorabiliaSaleMethod;

    const saleMethod: MemorabiliaSaleMethod[] = filters?.saleMethod?.includes(
      name
    )
      ? filters.saleMethod.filter((i) => i !== name) || []
      : [...(filters?.saleMethod || []), name];

    setFilters({ ...filters, saleMethod });
  };

  const isFilterOpen = Boolean(filters?.saleMethod?.length);

  return (
    <CheckboxFilter<MemorabiliaSaleMethod>
      title="Sale Type"
      options={availableOptions}
      selectedValues={filters?.saleMethod || []}
      getOptionLabel={(option) => option}
      getOptionValue={(option) => option}
      onChange={onCheckBoxChange}
      isOpen={isFilterOpen}
    />
  );
};

export default SaleMethodFilter;
