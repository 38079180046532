// Types
import { ChangeEvent } from 'react';
import { MemorabiliaProductType } from 'api/graphql-global-types';
import { GetMemorabilia_getMemorabilia_filters_types } from 'api/memorabilia/types/GetMemorabilia';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Components
import CheckboxFilter from 'components/common3/PageWithFiltersWrapper/components/Filters/components/CheckboxFilter/CheckboxFilter';

type ProductTypeFilterProps = {
  availableOptions: GetMemorabilia_getMemorabilia_filters_types[];
};

export const MemorabiliaProductTypeFilter = ({
  availableOptions,
}: ProductTypeFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name: MemorabiliaProductType = e.target
      .name as MemorabiliaProductType;

    const productType: MemorabiliaProductType[] = filters?.productType?.includes(
      name
    )
      ? filters.productType.filter((i) => i !== name) || []
      : [...(filters?.productType || []), name];

    setFilters({ ...filters, productType });
  };

  const sortedProductTypeOptions = availableOptions.slice().sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  const isFilterOpen = Boolean(filters?.productType?.length);

  return (
    <CheckboxFilter<GetMemorabilia_getMemorabilia_filters_types>
      title="Product Type"
      options={sortedProductTypeOptions}
      selectedValues={filters?.productType || []}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.name}
      onChange={onCheckBoxChange}
      isOpen={isFilterOpen}
    />
  );
};

export default MemorabiliaProductTypeFilter;
