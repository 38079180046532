import { ReactNode } from 'react';
import cn from 'classnames';
// Components
import FilterCollapse from 'ui3/FilterCollapse/FilterCollapse';
import Text from 'ui3/Text/Text';
// Styles
import styles from './FilterItem.module.scss';

type FilterItemProps = {
  title: string;
  filterBodyClassName?: string;
  className?: string;
  children?: ReactNode;
  isOpen?: boolean;
};

const FilterItem = ({
  title,
  filterBodyClassName,
  className,
  children,
  isOpen = false,
}: FilterItemProps) => (
  <FilterCollapse
    // put all filters to collapsed state by default on page open-as per ticket DEV-2484
    // added new parameter (isOpen) in case of some filters added trough query
    open={isOpen}
    className={cn(styles.filterItem, className)}
    bodyClassName={filterBodyClassName}
    title={
      <Text variant="subtitle2" className={styles.filterItemHeaderTitle}>
        {title}
      </Text>
    }
  >
    {children}
  </FilterCollapse>
);

export default FilterItem;
