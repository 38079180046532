// Types
import { ChangeEvent } from 'react';
import { StreamStatus } from 'api/graphql-global-types';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui2
import CheckBox from 'ui3/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './StreamStatusFilter.module.scss';

type StreamStatusFilterProps = {
  availableOptions: StreamStatus[];
};

const mapStreamStatusToLabel = (status: StreamStatus): string => {
  if (status === StreamStatus.Active) return 'Live';
  if (status === StreamStatus.Scheduled) return 'Upcoming';
  if (status === StreamStatus.Ended) return 'Past';
  return '';
};

export const StreamStatusFilter = ({
  availableOptions,
}: StreamStatusFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name: StreamStatus = e?.target?.name as StreamStatus;
    const streamStatus: StreamStatus[] = filters?.streamStatus?.includes(name)
      ? filters?.streamStatus?.filter((i) => i !== name) || []
      : [...(filters?.streamStatus || []), name];

    setFilters({ ...filters, streamStatus });
  };

  const isFilterOpen = !!filters?.streamStatus.length;

  return (
    <FilterItem title="Status" className={styles.test} isOpen={isFilterOpen}>
      <ul>
        {availableOptions?.map((option, index) => (
          <li key={`${index}_${option}`}>
            <CheckBox
              className={styles.filterListItemCheckbox}
              name={option || ''}
              label={mapStreamStatusToLabel(option)}
              labelClassName={styles.filterListItemCheckboxLabel}
              onChange={onCheckBoxChange}
              checked={Boolean(
                option && filters?.streamStatus?.includes(option)
              )}
            />
          </li>
        ))}
      </ul>
    </FilterItem>
  );
};

export default StreamStatusFilter;
