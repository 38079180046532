import cn from 'classnames';
// Ui
import RadioButton from 'ui3/RadioButton/RadioButton';
// Types
import { SortItem, SortingOptions } from '../../Sorting';

import styles from './SortingList.module.scss';

type SortingListProps = {
  isVisible: boolean;
  sortItems: SortItem[];
  sorting: SortingOptions;
  onSort: (option: SortItem) => void;
  onHide?: () => void;
};

const SortingList = ({
  isVisible,
  sortItems,
  sorting,
  onSort,
  onHide,
}: SortingListProps) => {
  const onSortItemChange = (option: SortItem) => {
    onSort(option);

    if (onHide) {
      onHide();
    }
  };

  const getIfItemChecked = (item: SortItem) =>
    sorting?.direction === item.direction && sorting?.order === item.order;

  return (
    <ul
      aria-hidden={!isVisible}
      className={cn(styles.root, {
        [styles.visible]: isVisible,
      })}
    >
      {sortItems.map((item, index) => (
        <li key={index}>
          <RadioButton
            className={styles.itemLabelWrapper}
            checked={getIfItemChecked(item)}
            onChange={() => onSortItemChange(item)}
            onClick={() => onSortItemChange(item)}
            label={item.label}
            labelClassName={cn(styles.itemLabel, {
              [styles.checked]: getIfItemChecked(item),
            })}
          />
        </li>
      ))}
    </ul>
  );
};

export default SortingList;
