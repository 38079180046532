import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Helpers
import { getCurrentTimeZoneOffset } from 'helpers/timeZone';
// UI
import DatePicker from 'ui3/DatePicker/DatePicker';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Icons
import CalendarIcon from '../../../../../../../public/images/icons/calendar.svg';

import styles from './DateFilter.module.scss';

type DateFilterProps = {
  availableRange: string[];
  datePickerVisible: boolean;
  setDatePickerVisible: Dispatch<SetStateAction<boolean>>;
  inModal?: boolean;
};

const DateFilter = ({
  availableRange,
  datePickerVisible,
  setDatePickerVisible,
  inModal,
}: DateFilterProps) => {
  const { filters, setFilters } = useFiltersContext();
  const minAvailable = availableRange[0];
  const maxAvailable = availableRange[1];

  const minSelected = !filters?.dateRange?.length
    ? dayjs().toDate()
    : dayjs(filters?.dateRange[0])
        .subtract(getCurrentTimeZoneOffset(), 'minute')
        .toDate();
  const maxSelected = !filters?.dateRange?.length
    ? dayjs(maxAvailable).toDate()
    : dayjs(filters?.dateRange[1])
        .subtract(getCurrentTimeZoneOffset(), 'minute')
        .toDate();

  const [datePickerStartDate, setDatePickerStartDate] = useState<
    Date | undefined | null
  >(minSelected);
  const [datePickerEndDate, setDatePickerEndDate] = useState<
    Date | undefined | null
  >(maxSelected);

  useEffect(() => {
    if (!filters?.dateRange?.length) {
      const minSelected = dayjs().toDate();
      const maxSelected = dayjs(maxAvailable).toDate();

      setDatePickerStartDate(minSelected);
      setDatePickerEndDate(maxSelected);
    }
  }, [minAvailable, maxAvailable, filters?.dateRange?.length]);

  const toggleDatePickerView = () => {
    setDatePickerVisible(!datePickerVisible);
  };

  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    const startDateTime: string = dayjs(start)
      .startOf('day')
      .add(getCurrentTimeZoneOffset(), 'minute')
      .toJSON();
    const endDateTime: string = dayjs(end)
      .endOf('day')
      .add(getCurrentTimeZoneOffset(), 'minute')
      .toJSON();

    const dateRange: string[] =
      dayjs(start).isSame(dayjs(minAvailable)) &&
      dayjs(end).isSame(dayjs(maxAvailable))
        ? []
        : [startDateTime, endDateTime];

    setDatePickerStartDate(start);
    setDatePickerEndDate(end);

    if (start && end) {
      setDatePickerVisible(!datePickerVisible);
      setFilters({
        ...filters,
        dateRange,
      });
    }
  };

  const getFormattedDate = (
    start: Date | undefined | null,
    end: Date | undefined | null
  ): string =>
    `${start ? dayjs(start).format('DD MMM YYYY') : 'Select start date'} - ${
      end ? dayjs(end).format('DD MMM YYYY') : 'Select end date'
    }`;

  const isFilterOpen = !!filters?.dateRange.length;

  if (!minAvailable && !maxAvailable) {
    return null;
  }

  return (
    <FilterItem
      title="Date"
      className={styles.filterDateRangeContainer}
      isOpen={isFilterOpen}
    >
      <div className={styles.filterDateRangeWrapper}>
        <div className={styles.filterDateRange}>
          {getFormattedDate(datePickerStartDate, datePickerEndDate)}

          <button
            className={cn(styles.filterDateRangeToggle, {
              [styles.active]: datePickerVisible,
            })}
            onClick={toggleDatePickerView}
          >
            <CalendarIcon width={14} height={14} />
          </button>
        </div>

        {datePickerVisible && (
          <DatePicker
            isFilter={true}
            showYearDropdown
            showMonthDropdown
            yearDropdownItemNumber={24}
            calendarClassName={styles.filterDateRangeDatePicker}
            onChange={onDateRangeChange}
            selected={datePickerStartDate}
            startDate={datePickerStartDate}
            endDate={datePickerEndDate}
            onCalendarClose={toggleDatePickerView}
            selectsRange
            inline
            {...(!inModal && { onClickOutside: toggleDatePickerView })}
          />
        )}
      </div>
    </FilterItem>
  );
};

export default DateFilter;
