// Types
import { ChangeEvent } from 'react';
import { MerchType } from 'api/graphql-global-types';
import { GetMerchProducts_getMerchProducts_filters_type } from 'api/merch/types/GetMerchProducts';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Components
import CheckboxFilter from '../CheckboxFilter/CheckboxFilter';

type MerchTypeFilterProps = {
  availableOptions: GetMerchProducts_getMerchProducts_filters_type[];
};

export const MerchTypeFilter = ({ availableOptions }: MerchTypeFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name: MerchType = e?.target?.name as MerchType;

    const merchType: MerchType[] = filters?.merchType?.includes(name)
      ? filters?.merchType?.filter((i) => i !== name) || []
      : [...(filters?.merchType || []), name];

    setFilters({ ...filters, merchType });
  };

  const sortedMerchTypeOptions = availableOptions.slice().sort((a, b) => {
    const nameA = a?.name?.toLowerCase() || '';
    const nameB = b?.name?.toLowerCase() || '';

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  const optionName = (typeName) => {
    switch (typeName) {
      case 'TShirt': {
        return 'T-Shirts';
      }
      case 'Hoodie': {
        return 'Hoodies';
      }
      case 'Hat': {
        return 'Hats';
      }
      case 'Joggers': {
        return 'Joggers';
      }
      case 'RashGuard': {
        return 'Compression Shirts';
      }
    }
  };

  const isFilterOpen = Boolean(filters?.merchType?.length);

  return (
    <CheckboxFilter<GetMerchProducts_getMerchProducts_filters_type>
      title="Type"
      options={sortedMerchTypeOptions}
      selectedValues={filters?.merchType ?? []}
      getOptionValue={(merchType) => String(merchType.name)}
      getOptionLabel={(merchType) => optionName(merchType.name)}
      onChange={onCheckBoxChange}
      isOpen={isFilterOpen}
    />
  );
};

export default MerchTypeFilter;
