import React from 'react';
import { Range as RcRange } from 'rc-slider';
// Types
import { RangeProps } from 'rc-slider';
// Styles
import 'rc-slider/assets/index.css';
import styles from './Range.module.scss';

const Range: React.FC<RangeProps> = ({ ...rest }) => {
  const handleStyles = {
    width: '18px',
    height: '18px',
    backgroundColor: '#FFFFFF',
    border: '2px solid #d8a445',
    borderRadius: '100%',
  };

  const trackStyles = {
    height: '5px',
    backgroundColor: '#d8a445',
    border: '0.5px solid #939393',
    borderRadius: '10px',
    marginTop: '5px',
    transform: 'translate(0)',
  };

  return (
    <div className={styles.rangeWrapper}>
      <RcRange
        className={styles.rangeContainer}
        handleStyle={[handleStyles, handleStyles]}
        trackStyle={[trackStyles, trackStyles]}
        railStyle={{
          ...trackStyles,
          backgroundColor: '#ffffff',
          marginTop: '5px',
        }}
        dotStyle={{
          display: 'none',
        }}
        activeDotStyle={{
          display: 'none',
        }}
        {...rest}
      />
    </div>
  );
};

export default Range;
