// Types
import cn from 'classnames';
import { GetMerchProducts_getMerchProducts_filters_size } from 'api/merch/types/GetMerchProducts';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui3
import Button from 'ui3/Button/Button';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './SizeFilter.module.scss';

type SizeFilterProps = {
  availableOptions: GetMerchProducts_getMerchProducts_filters_size[];
};

export const SizeFilter = ({ availableOptions }: SizeFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onButtonClick = (name: string) => {
    // Toggle the filter: remove if it exists, add if it doesn't.
    const size: string[] = filters?.size?.includes(name)
      ? filters?.size?.filter((i) => i !== name) || []
      : [...(filters?.size || []), name];

    setFilters({ ...filters, size });
  };

  const isFilterOpen = Boolean(filters?.size?.length);

  return (
    <FilterItem title="Size" isOpen={isFilterOpen}>
      <ul className={styles.filterList}>
        {availableOptions?.map((option, index) => {
          const optionName = option.name || '';
          const isSelected = Boolean(
            optionName && filters?.size?.includes(optionName)
          );
          const autoSize = ['one size'].includes(optionName.toLowerCase());

          return (
            <li key={`${index}_${optionName}`}>
              <Button
                variant="secondary"
                size="small"
                onClick={() => onButtonClick(optionName)}
                className={cn(styles.filterButton, {
                  [styles.selected]: isSelected,
                  [styles.autoSize]: autoSize,
                })}
              >
                {optionName}
              </Button>
            </li>
          );
        })}
      </ul>
    </FilterItem>
  );
};

export default SizeFilter;
