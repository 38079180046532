import { useState, ReactNode } from 'react';
import cn from 'classnames';
import dynamic from 'next/dynamic';
// Hooks
import { useBreakpoints, useHasMounted } from 'hooks';
// Types
import { SortKey } from 'components/common3/Sorting/Sorting';
import { GetAllAmas_amas_filters } from 'api/ama/types/GetAllAmas';
import { GetStores_stores_filters } from 'api/store/types/GetStores';
import { GetStreams_streamsV2_filtersV2 } from 'api/streams/types/GetStreams';
import { SearchLocation } from 'api/graphql-analytics-global-types';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui
import Loader from 'ui3/Loader/Loader';
// Layout
import Main from 'layouts/Main/Main';
// Components
import Filters from 'components/common3/PageWithFiltersWrapper/components/Filters/Filters';
// Dynamic imports
const Sorting = dynamic(() => import('components/common3/Sorting/Sorting'), {
  ssr: true,
});
const FiltersToggle = dynamic(
  () =>
    import(
      'components/common3/PageWithFiltersWrapper/components/FiltersToggle/FiltersToggle'
    ),
  { ssr: true }
);
const SearchBar = dynamic(
  () =>
    import(
      'components/common3/PageWithFiltersWrapper/components/SearchBar/SearchBar'
    ),
  { ssr: true }
);
const SearchBarToggle = dynamic(
  () =>
    import(
      'components/common3/PageWithFiltersWrapper/components/SearchBarToggle/SearchBarToggle'
    ),
  { ssr: true }
);
// Styles
import styles from './PageWithFiltersWrapper.module.scss';

export type ExtraStoreFilters = {
  isStreamer?: boolean;
  isShoppingEnabled?: boolean;
};

type AllFilters =
  | Omit<GetAllAmas_amas_filters, '__typename'>
  | Omit<GetStores_stores_filters, '__typename'>
  | Omit<GetStreams_streamsV2_filtersV2, '__typename'>
  | Omit<ExtraStoreFilters, '__typename'>;

type PageWithFiltersWrapperProps = {
  sortKey: SortKey;
  title?: string;
  loading?: boolean;
  children?: ReactNode;
  filters?: Partial<AllFilters> | null | undefined;
  isPage?: boolean;
  noClear?: boolean;
  searchBarLocation: SearchLocation;
  singleCategory?: boolean;
  skipFilters?: boolean;
  // headerSpaceForMobile is for pages without a banner
  // if there is a banner, put false here and you get no top-margin
  headerSpaceForMobile?: boolean;
};

const PageWithFiltersWrapper = ({
  sortKey,
  loading,
  children,
  filters,
  isPage = true,
  noClear = false,
  searchBarLocation,
  singleCategory = false,
  skipFilters = false,
  headerSpaceForMobile = true,
}: PageWithFiltersWrapperProps) => {
  const [filtersModalIsVisible, setFiltersModalIsVisible] = useState<boolean>(
    false
  );
  const [
    searchBarModalIsVisible,
    setSearchBarModalIsVisible,
  ] = useState<boolean>(false);

  const { sorting, setSorting } = useFiltersContext();
  const { isBreakpointL } = useBreakpoints();
  const hasMounted = useHasMounted();

  const isDesktopComponentsVisible = hasMounted ? isBreakpointL : true;
  const isMobileComponentsVisible = hasMounted ? !isBreakpointL : true;

  const toggleFiltersVisibility = () => {
    setFiltersModalIsVisible(!filtersModalIsVisible);
  };

  const toggleSearchBarVisibility = () => {
    setSearchBarModalIsVisible(!searchBarModalIsVisible);
  };

  const closeSearchBarModal = () => {
    setSearchBarModalIsVisible(false);
  };

  return (
    <div className={styles.root}>
      <Main withoutSidesPadding>
        <div className={styles.pageWrapper}>
          {!skipFilters && (
            <>
              <div className={styles.borderElement}></div>
              <aside className={styles.asideWrapper}>
                {isDesktopComponentsVisible && (
                  <Filters
                    filters={filters}
                    loading={loading}
                    noClear={noClear}
                  />
                )}
              </aside>
            </>
          )}

          <div className={styles.contentWrapper}>
            {!noClear && (
              <div className={cn(styles.contentHeader, styles.desktop)}>
                {isDesktopComponentsVisible && (
                  <>
                    <SearchBar
                      sortKey={sortKey}
                      wrapperClassName={styles.sectionSearchBar}
                      location={searchBarLocation}
                      singleCategory={singleCategory}
                    />

                    <Sorting
                      wrapperClassName={styles.sectionSorting}
                      sortKey={sortKey}
                      sorting={sorting}
                      setSorting={setSorting}
                    />
                  </>
                )}
              </div>
            )}

            <div
              className={cn(styles.contentHeader, styles.mobile, {
                [styles.mobilePage]: isPage && headerSpaceForMobile,
              })}
            >
              {isMobileComponentsVisible && (
                <>
                  {isPage && (
                    <SearchBarToggle
                      searchBarModalIsVisible={searchBarModalIsVisible}
                      toggleSearchBarVisibility={toggleSearchBarVisibility}
                    >
                      <SearchBar
                        inModal
                        sortKey={sortKey}
                        onSearch={closeSearchBarModal}
                        location={searchBarLocation}
                        singleCategory={singleCategory}
                      />
                    </SearchBarToggle>
                  )}

                  <FiltersToggle
                    filtersModalIsVisible={filtersModalIsVisible}
                    toggleFiltersVisibility={toggleFiltersVisibility}
                  >
                    <Filters
                      filters={filters}
                      setFiltersModalIsVisible={setFiltersModalIsVisible}
                      sortKey={sortKey}
                      loading={loading}
                      noClear={noClear}
                      inModal
                    />
                  </FiltersToggle>
                </>
              )}
            </div>

            <div className={styles.contentBody}>
              {loading && <Loader loading size="xLarge" />}
              {children}
            </div>
          </div>
        </div>
      </Main>
    </div>
  );
};

export default PageWithFiltersWrapper;
