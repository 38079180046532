// Types
import { ChangeEvent } from 'react';
import { PublicStreamCategory, StreamType } from 'api/graphql-global-types';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui2
import CheckBox from 'ui3/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './StreamTypeFilter.module.scss';

type StreamTypeFilterProps = {
  availableOptions: Partial<
    [
      StreamType.Interview,
      StreamType.Stream,
      StreamType.SponsoredAppearanceDraft,
      StreamType.SponsoredAppearanceLive,
      StreamType.SponsoredAppearanceRec
    ]
  >;
};

[
  StreamType.Stream,
  StreamType.SponsoredAppearanceDraft,
  StreamType.SponsoredAppearanceLive,
  StreamType.SponsoredAppearanceRec,
];
const mapStreamTypeToLabel = (type: StreamType): string => {
  if (
    [
      StreamType.Interview,
      StreamType.SponsoredAppearanceDraft,
      StreamType.SponsoredAppearanceLive,
      StreamType.SponsoredAppearanceRec,
    ].includes(type)
  ) {
    return 'Interview';
  }
  if (type === StreamType.Stream) {
    return 'Stream';
  }
  return '';
};

const streamTypeToCategoryMap: Record<StreamType, PublicStreamCategory> = {
  [StreamType.Stream]: PublicStreamCategory.Stream,
  [StreamType.Interview]: PublicStreamCategory.Interview,
  [StreamType.SponsoredAppearanceLive]: PublicStreamCategory.Interview,
  [StreamType.SponsoredAppearanceRec]: PublicStreamCategory.Interview,
  [StreamType.SponsoredAppearanceDraft]: PublicStreamCategory.Interview,
};

export const StreamTypeFilter = ({
  availableOptions,
}: StreamTypeFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name: StreamType = e?.target?.name as StreamType;
    const mappedCategory = streamTypeToCategoryMap[name];

    const streamType: PublicStreamCategory[] = filters?.streamType?.includes(
      mappedCategory
    )
      ? filters?.streamType?.filter((i) => i !== mappedCategory) || []
      : [...(filters?.streamType || []), mappedCategory];

    setFilters({ ...filters, streamType });
  };

  const isFilterOpen = Boolean(filters?.streamType?.length);

  return (
    <FilterItem title="Type" className={styles.test} isOpen={isFilterOpen}>
      <ul>
        {availableOptions?.map((option, index) => (
          <li key={`${index}_${option}`}>
            <CheckBox
              className={styles.filterListItemCheckbox}
              name={option || ''}
              label={mapStreamTypeToLabel(option ?? StreamType.Stream)}
              labelClassName={styles.filterListItemCheckboxLabel}
              onChange={onCheckBoxChange}
              checked={Boolean(
                option &&
                  filters?.streamType?.includes(streamTypeToCategoryMap[option])
              )}
            />
          </li>
        ))}
      </ul>
    </FilterItem>
  );
};

export default StreamTypeFilter;
