import { ChangeEvent } from 'react';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui2
import CheckBox from 'ui3/CheckBox/CheckBox';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './ProfileTypeFilter.module.scss';

type ProfileTypeFilterProps = {
  isOrganization?: boolean;
  isContentCreator?: boolean;
};

export const ProfileTypeFilter = ({
  isOrganization,
  isContentCreator,
}: ProfileTypeFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onIsOrganization = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      ...{ isOrganization: checked ? ['true'] : [] },
    });
  };

  const onIsContentCreator = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      ...{ isContentCreator: checked ? ['true'] : [] },
    });
  };

  const isFilterOpen =
    Boolean(filters?.isOrganization.length) ||
    Boolean(filters?.isContentCreator.length);

  return (
    <FilterItem title="Profile Type" isOpen={isFilterOpen}>
      <CheckBox
        className={styles.filterListItemCheckbox}
        name="isOrganization"
        label="Organization"
        labelClassName={styles.filterListItemCheckboxLabel}
        onChange={onIsOrganization}
        checked={isOrganization}
      />

      <CheckBox
        className={styles.filterListItemCheckbox}
        name="isContentCreator"
        label="Content Creator"
        labelClassName={styles.filterListItemCheckboxLabel}
        onChange={onIsContentCreator}
        checked={isContentCreator}
      />
    </FilterItem>
  );
};

export default ProfileTypeFilter;
