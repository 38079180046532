// Types
import { ColorItemFilter } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
import { GetMerchProducts_getMerchProducts_filters_color } from 'api/merch/types/GetMerchProducts';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui3
import ColorPicker, { ColorPickerOption } from 'ui3/ColorPicker/ColorPicker';
// Components
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './ColorFilter.module.scss';

type ColorFilterProps = {
  availableOptions: GetMerchProducts_getMerchProducts_filters_color[];
};

export const convertColorToString = ({
  color,
  colorCode,
  colorCode2,
}: ColorItemFilter): string => {
  return `${color || ''}_${colorCode || ''}_${colorCode2 || ''}`;
};

export const parseColorString = (colorString: string): ColorItemFilter => {
  const arr = colorString?.split('_') || [];

  const color = arr?.[0]?.length ? arr?.[0] : null;
  const colorCode = arr?.[1]?.length ? arr?.[1] : null;
  const colorCode2 = arr?.[2]?.length ? arr?.[2] : null;

  return {
    color,
    colorCode,
    colorCode2,
  };
};

const ColorFilter = ({ availableOptions }: ColorFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onColorChange = (
    value: string,
    colorCode: string,
    colorCode2: string | null
  ) => {
    const colorString = convertColorToString({
      color: value,
      colorCode,
      colorCode2,
    });

    const color: string[] = filters?.color?.includes(colorString)
      ? filters?.color?.filter((i) => i !== colorString) || []
      : [...(filters?.color || []), colorString];

    setFilters({ ...filters, color });
  };

  const isFilterOpen = !!filters?.color.length;

  const colorOptions: ColorPickerOption[] =
    availableOptions?.map((i) => ({
      value: i.color || '',
      colorCode: i.colorCode || '',
      colorCode2: i.colorCode2 && i.colorCode2 !== 'null' ? i.colorCode2 : null,
      disabled: false,
    })) || [];

  return (
    <FilterItem title="Color" isOpen={isFilterOpen}>
      <ColorPicker
        className={styles.filterListItemCheckbox}
        onChange={onColorChange}
        checkedValuesOptions={filters?.color?.map((i) => parseColorString(i))}
        options={colorOptions}
        role="checkbox"
        fillFullWidth
      />
    </FilterItem>
  );
};

export default ColorFilter;
