import {
  useState,
  useContext,
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
} from 'react';
import { useRouter } from 'next/router';
import { parse, stringify } from 'query-string';
// Types
import { SortingOptions } from 'components/common/Sorting/Sorting';
import {
  MerchType,
  StreamStatus,
  PublicStreamCategory,
  MemorabiliaProductType,
  MemorabiliaSaleMethod,
  GenderInputFilter,
} from 'api/graphql-global-types';
import { GetMerchProducts_getMerchProducts_filters_color } from 'api/merch/types/GetMerchProducts';

export type StoresListFilter =
  | 'hasActiveAma'
  | 'hasActiveMerch'
  | 'hasActiveStreams'
  | 'hasActiveProducts'
  | 'hasActiveExperiences';

type AthleteFilter = {
  athlete: string[];
};

type AthleteIdsFilter = {
  athleteIds: string[];
};

type OrganizationIdsFilter = {
  organizationIds: string[];
};

type ContentCreatorIdsFilter = {
  contentCreatorIds: string[];
};

type DaysToResponseFilter = {
  daysToResponse: number[];
};

type PriceRangeFilter = {
  priceRange: number[];
};

type TimeZoneFilter = {
  tzcodes: string[];
};

type DateRangeFilter = {
  dateRange: string[];
};

type AthleteSportsFilter = {
  sports: number[];
};

type HashtagIdsFilter = {
  hashtagIds: number[];
};

type GenderFilter = {
  genderV2: GenderInputFilter[];
};

type MerchTypeFilter = {
  merchType: MerchType[];
};

type ProductTypeFilter = {
  productType: MemorabiliaProductType[];
};

type SaleMethodFilter = {
  saleMethod: MemorabiliaSaleMethod[];
};

type SizeFilter = {
  size: string[];
};

type StreamStatusFilter = {
  streamStatus: StreamStatus[];
};

type StreamTypeFilter = {
  streamType: PublicStreamCategory[];
};

export type ColorItemFilter = Omit<
  GetMerchProducts_getMerchProducts_filters_color,
  '__typename'
>;

type ColorFilter = {
  color: string[];
};

type IsStreamerFilter = {
  isStreamer: string[];
};

type IsShoppingEnabledFilter = {
  isShoppingEnabled: string[];
};

type IsOrganizationFilter = {
  isOrganization: string[];
};

type IsContentCreatorFilter = {
  isContentCreator: string[];
};

export type PageSearchOptions = {
  [key: string]: string;
};

interface Filter {
  [key: string]: string[] | number[] | string;
}

export type PageFilters = AthleteFilter &
  AthleteIdsFilter &
  OrganizationIdsFilter &
  ContentCreatorIdsFilter &
  DaysToResponseFilter &
  PriceRangeFilter &
  TimeZoneFilter &
  DateRangeFilter &
  AthleteSportsFilter &
  HashtagIdsFilter &
  GenderFilter &
  MerchTypeFilter &
  ProductTypeFilter &
  SaleMethodFilter &
  SizeFilter &
  ColorFilter &
  StreamStatusFilter &
  StreamTypeFilter &
  IsStreamerFilter &
  IsShoppingEnabledFilter &
  IsOrganizationFilter &
  IsContentCreatorFilter &
  Filter;

type HiddenFilters = {
  hideStoreFilter?: boolean;
  hideAthletesFilter?: boolean;
  hideOrganizationsFilter?: boolean;
  hideContentCreatorsFilter?: boolean;
  hideSports?: boolean;
  hideProductType?: boolean;
  hasAthleteSlug?: boolean;
};

type FiltersContextState = {
  filters: PageFilters;
  sorting: SortingOptions;
  search: PageSearchOptions;
  searchKey?: string;
  storesListFilter?: StoresListFilter | null;
} & HiddenFilters;

type FiltersContextHandlers = {
  setFiltersContext: Dispatch<SetStateAction<FiltersContextState>>;
  setFilters: (filters: PageFilters) => void;
  setSorting: (options: SortingOptions) => void;
  setSearch: (options: PageSearchOptions) => void;
  clearFilters: () => void;
};

type FiltersContext = FiltersContextHandlers & FiltersContextState;

export type FiltersContextType = FiltersContext;

export const FiltersContext = createContext<FiltersContextType>(
  {} as FiltersContextType
);

type FiltersProviderProps = {
  children?: ReactNode;
  storesListFilter?: StoresListFilter;
  initialSorting?: SortingOptions;
  searchKey?: string;
} & HiddenFilters;

const FiltersProvider = ({
  children,
  storesListFilter,
  initialSorting,
  searchKey,
  hideStoreFilter = true,
  hideAthletesFilter,
  hideOrganizationsFilter,
  hideContentCreatorsFilter,
  hideSports,
  hideProductType,
  hasAthleteSlug = false,
}: FiltersProviderProps) => {
  const { push, query } = useRouter();

  const initFilters = {
    athlete: [],
    athleteIds: [],
    organizationIds: [],
    contentCreatorIds: [],
    daysToResponse: [],
    priceRange: [],
    tzcodes: [],
    dateRange: [],
    sports: [],
    hashtagIds: [],
    genderV2: [],
    merchType: [],
    productType: [],
    saleMethod: [],
    size: [],
    color: [],
    streamStatus: [],
    streamType: [],
    isStreamer: [],
    isShoppingEnabled: [],
    isContentCreator: [],
    isOrganization: [],
  };

  const initSearch = {
    ...(searchKey && { [searchKey]: '' }),
  };

  const parsedQueryParams = parse(stringify(query)) as PageSearchOptions;
  const searchQueryValue = searchKey ? parsedQueryParams[searchKey] : '';
  const productTypeQuery = (query?.productType as string)?.split(',') || [];

  const {
    athlete,
    athleteIds,
    organizationIds,
    contentCreatorIds,
    daysToResponse,
    priceRange,
    tzcodes,
    dateRange,
    sports,
    hashtagIds,
    genderV2,
    merchType,
    productType,
    saleMethod,
    size,
    color,
    direction,
    order,
    streamStatus,
    streamType,
    isStreamer,
    isShoppingEnabled,
    isContentCreator,
    isOrganization,
  }: PageFilters & SortingOptions & PageSearchOptions =
    (parse(stringify(query) as string, {
      arrayFormat: 'comma',
    }) as PageFilters & SortingOptions & PageSearchOptions) || {};

  const getFilter = (filter: any, filterKey: string, isNumber = false): any => {
    return filter
      ? {
          [filterKey]: Array.isArray(filter)
            ? filter?.map((i) => (isNumber ? Number(i) : i))
            : [filter]?.map((i) => (isNumber ? Number(i) : i)),
        }
      : { [filterKey]: initFilters?.[filterKey] };
  };

  const [filtersContext, setFiltersContext] = useState<FiltersContextState>({
    filters: {
      ...getFilter(athlete, 'athlete'),
      ...getFilter(athleteIds, 'athleteIds'),
      ...getFilter(organizationIds, 'organizationIds'),
      ...getFilter(contentCreatorIds, 'contentCreatorIds'),
      ...getFilter(daysToResponse, 'daysToResponse'),
      ...getFilter(priceRange, 'priceRange'),
      ...getFilter(tzcodes, 'tzcodes'),
      ...getFilter(dateRange, 'dateRange'),
      ...getFilter(sports, 'sports', true),
      ...getFilter(hashtagIds, 'hashtagIds', true),
      ...getFilter(genderV2, 'genderV2'),
      ...getFilter(merchType, 'merchType'),
      ...getFilter(productType, 'productType'),
      ...getFilter(saleMethod, 'saleMethod'),
      ...getFilter(size, 'size'),
      ...getFilter(color, 'color'),
      ...getFilter(streamStatus, 'streamStatus'),
      ...getFilter(streamType, 'streamType'),
      ...getFilter(isStreamer, 'isStreamer'),
      ...getFilter(isShoppingEnabled, 'isShoppingEnabled'),
      ...getFilter(isOrganization, 'isOrganization'),
      ...getFilter(isContentCreator, 'isContentCreator'),
    },
    sorting: {
      direction: direction || initialSorting?.direction,
      order: order || initialSorting?.order,
    },
    search: {
      ...(searchKey && { [searchKey]: searchQueryValue }),
    },
    storesListFilter: storesListFilter || null,
    searchKey: searchKey,
  });

  const updateRoute = (
    sorting: SortingOptions,
    filters?: PageFilters,
    search?: PageSearchOptions
  ) => {
    const sortingValues =
      sorting?.direction !== initialSorting?.direction ||
      sorting?.order !== initialSorting?.order
        ? sorting
        : {};

    const getFiltersWithoutSearchValue = () => {
      const res = {};
      if (searchKey && filters) {
        Object.keys(filters).forEach((key) => {
          if (
            key !== searchKey ||
            (['isStreamer', 'isShoppingEnabled'].includes(key) && filters[key])
          ) {
            Object.assign(res, { [key]: filters[key] });
          }

          if (
            key !== searchKey ||
            (['isOrganization', 'isContentCreator'].includes(key) &&
              filters[key])
          ) {
            Object.assign(res, { [key]: filters[key] });
          }
        });
      }
      return res;
    };

    // added for the athlete pages where we have "athleteSlug" query so filters don't revert it to default
    const queryString = hasAthleteSlug
      ? stringify(
          {
            athleteSlug: query.athleteSlug,
            ...sortingValues,
            ...getFiltersWithoutSearchValue(),
            ...(searchKey && search?.[searchKey]?.length ? search : {}),
          },
          { arrayFormat: 'comma' }
        )
      : stringify(
          {
            ...sortingValues,
            ...getFiltersWithoutSearchValue(),
            ...(searchKey && search?.[searchKey]?.length ? search : {}),
          },
          { arrayFormat: 'comma' }
        );

    push({ query: queryString?.length ? queryString : '' });
  };

  const setFilters = (filters: PageFilters) => {
    setFiltersContext({
      ...filtersContext,
      filters: filters,
    });
    updateRoute(filtersContext.sorting, filters);
  };

  const clearFilters = () => {
    setFilters(initFilters);
    setFiltersContext({
      ...filtersContext,
      filters: initFilters,
      search: initSearch,
    });
    updateRoute(filtersContext.sorting, initFilters, initSearch);
  };

  // this is used for a specific scenario, when performing a generalized search for all memorabilia or products
  useEffect(() => {
    if (
      !query?.athleteIds &&
      !query.organizationIds &&
      !query.contentCreatorIds &&
      query?.productType
    ) {
      setFiltersContext({
        ...filtersContext,
        filters: {
          ...initFilters,
          productType: productTypeQuery as MemorabiliaProductType[],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.productType]);

  const setSorting = (sorting: SortingOptions) => {
    setFiltersContext((prev) => ({
      ...prev,
      sorting: { ...prev.sorting, ...sorting },
    }));
    updateRoute(sorting, {
      athlete,
      athleteIds,
      organizationIds,
      contentCreatorIds,
      daysToResponse,
      priceRange,
      tzcodes,
      dateRange,
      sports,
      hashtagIds,
      genderV2,
      merchType,
      productType,
      saleMethod,
      size,
      color,
      streamStatus,
      streamType,
      isStreamer,
      isShoppingEnabled,
      isContentCreator,
      isOrganization,
    });
  };

  const setSearch = (search: PageSearchOptions) => {
    setFiltersContext((prev) => ({
      ...prev,
      search: { ...prev.search, ...search },
    }));
    updateRoute(filtersContext.sorting, filtersContext.filters, search);
  };

  return (
    <FiltersContext.Provider
      value={{
        ...filtersContext,
        setFiltersContext: setFiltersContext,
        setFilters,
        setSorting,
        setSearch,
        clearFilters,
        hideStoreFilter,
        hideAthletesFilter,
        hideContentCreatorsFilter,
        hideOrganizationsFilter,
        hideSports,
        hideProductType,
        hasAthleteSlug,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

const useFiltersContext = (): FiltersContextType => {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error('useFiltersContext must be used within a FiltersProvider');
  }

  return context;
};

export { FiltersProvider, useFiltersContext };
