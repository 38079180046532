import { useState, Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
// Types
import { GetAllAmas_amas_filters } from 'api/ama/types/GetAllAmas';
import { GetStores_stores_filters } from 'api/store/types/GetStores';
import { GetStreams_streamsV2_filtersV2 } from 'api/streams/types/GetStreams';
import { GetMerchProducts_getMerchProducts_filters } from 'api/merch/types/GetMerchProducts';
import { GetMemorabilia_getMemorabilia_filters } from 'api/memorabilia/types/GetMemorabilia';
import { StreamStatus, StreamType } from 'api/graphql-global-types';
import { SortKey } from 'components/common3/Sorting/Sorting';
import { ExtraStoreFilters } from '../../PageWithFiltersWrapper';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui3
import Button from 'ui3/Button/Button';
import Loader from 'ui3/Loader/Loader';
import Text from 'ui3/Text/Text';
// Components
import GenderFilter from './components/GenderFilter/GenderFilter';
import ProfileTypeFilter from './components/ProfileTypeFilter/ProfileTypeFilter';
import AthletesFilter from './components/AthletesFilter/AthletesFilter';
import OrganizationsFilter from './components/OrganizationsFilter/OrganizationsFilter';
import ContentCreatorsFilter from './components/ContentCreatorsFilter/ContentCreatorsFilter';
import ResponseDayFilter from './components/ResponseDayFilter/ResponseDayFilter';
import PriceFilter from './components/PriceFilter/PriceFilter';
import AthleteSportFilter from './components/AthleteSportFilter/AthleteSportFilter';
import MerchTypeFilter from './components/MerchTypeFilter/MerchTypeFilter';
import SizeFilter from './components/SizeFilter/SizeFilter';
import ColorFilter from './components/ColorFilter/ColorFilter';
import DateFilter from './components/DateFilter/DateFilter';
import TagsFilter from './components/TagsFilter/TagsFilter';
import StreamStatusFilter from './components/StreamStatusFilter/StreamStatusFilter';
import MemorabiliaProductTypeFilter from './components/MemorabiliaProductTypeFilter/MemorabiliaProductTypeFilter';
import SaleMethodFilter from './components/SaleMethodFilter/SaleMethodFilter';
import ProductTypeFilter from './components/ProductTypeFilter/ProductTypeFilter';
import Sorting from 'components/common3/Sorting/Sorting';
import StreamTypeFilter from './components/StreamTypeFilter/StreamTypeFilter';
// Styles
import styles from './Filters.module.scss';

type AllFilters =
  | Omit<GetAllAmas_amas_filters, '__typename'>
  | Omit<GetStores_stores_filters, '__typename'>
  | Omit<GetStreams_streamsV2_filtersV2, '__typename'>
  | Omit<GetMerchProducts_getMerchProducts_filters, '__typename'>
  | Omit<GetMemorabilia_getMemorabilia_filters, '__typename'>
  | Omit<ExtraStoreFilters, '__typename'>;

type FiltersProps = {
  inModal?: boolean;
  loading?: boolean;
  setFiltersModalIsVisible?: Dispatch<SetStateAction<boolean>>;
  filters?: Partial<AllFilters> | null | undefined;
  sortKey?: SortKey;
  noClear?: boolean;
};

const Filters = ({
  inModal,
  loading,
  setFiltersModalIsVisible,
  filters: initialFilters,
  sortKey,
  noClear = false,
}: FiltersProps) => {
  const { query, pathname } = useRouter();

  const isOrganizationPage = pathname.includes('/organizations');

  const {
    clearFilters,
    hideStoreFilter = true,
    hideContentCreatorsFilter,
    hideOrganizationsFilter,
    hideAthletesFilter,
    hideSports,
    hideProductType,
    sorting,
    setSorting,
    filters: contextFilters,
  } = useFiltersContext();

  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);

  const filters = initialFilters || {};

  const allEmpty = Object.keys(contextFilters).every(function (key) {
    return contextFilters[key].length === 0;
  });

  const setDefaultFiltersState = () => {
    if ('scheduleDate' in filters && filters?.scheduleDate) {
      setDatePickerVisible(false);
    }

    if (setFiltersModalIsVisible) {
      setFiltersModalIsVisible(false);
    }
  };

  const onFilterClear = () => {
    clearFilters();
    setDefaultFiltersState();
  };

  const onModalApplyClick = () => {
    if (setFiltersModalIsVisible) {
      setFiltersModalIsVisible(false);
    }
  };

  return (
    <div className={cn(styles.root)}>
      <Text variant="h5">Filters</Text>
      <div className={cn(styles.filtersBody, { [styles.inModal]: inModal })}>
        {inModal && sortKey && (
          <Sorting
            wrapperClassName={styles.sectionSorting}
            sortKey={sortKey}
            sorting={sorting}
            setSorting={setSorting}
            inModal
          />
        )}

        {inModal && loading && <Loader loading size="xLarge" />}

        <>
          {'streamStatusV2' in filters && filters?.streamStatusV2 && (
            <StreamStatusFilter
              availableOptions={[
                StreamStatus.Active,
                StreamStatus.Scheduled,
                StreamStatus.Ended,
              ]}
            />
          )}

          {'streamType' in filters && filters?.streamType && (
            <StreamTypeFilter
              availableOptions={[StreamType.Interview, StreamType.Stream]}
            />
          )}

          {'genderV2' in filters && filters?.genderV2 && (
            <GenderFilter availableOptions={filters?.genderV2} />
          )}

          {!hideProductType && 'types' in filters && filters?.types && (
            <MemorabiliaProductTypeFilter availableOptions={filters?.types} />
          )}

          {'price' in filters && filters?.price && (
            <PriceFilter availableRange={filters.price} />
          )}

          {'saleMethods' in filters && filters?.saleMethods && (
            <SaleMethodFilter availableOptions={filters.saleMethods} />
          )}

          {'size' in filters && filters?.size && (
            <SizeFilter availableOptions={filters?.size} />
          )}

          {'dateToResponse' in filters && filters?.dateToResponse && (
            <ResponseDayFilter availableRange={filters.dateToResponse} />
          )}

          {!hideStoreFilter && (
            <ProductTypeFilter
              isStreamer={query.isStreamer === 'true'}
              isShoppingEnabled={query.isShoppingEnabled === 'true'}
            />
          )}

          {isOrganizationPage && (
            <ProfileTypeFilter
              isOrganization={query.isOrganization === 'true'}
              isContentCreator={query.isContentCreator === 'true'}
            />
          )}

          {!hideSports && 'sports' in filters && filters?.sports && (
            <AthleteSportFilter availableOptions={filters?.sports} />
          )}

          {!hideAthletesFilter && !loading && <AthletesFilter />}

          {!hideOrganizationsFilter && !loading && <OrganizationsFilter />}

          {!hideContentCreatorsFilter && !loading && <ContentCreatorsFilter />}

          {'hashtags' in filters && filters?.hashtags && (
            <TagsFilter availableOptions={filters?.hashtags} />
          )}

          {'type' in filters && filters?.type && (
            <MerchTypeFilter availableOptions={filters?.type} />
          )}

          {'color' in filters && filters?.color && (
            <ColorFilter availableOptions={filters?.color} />
          )}

          {'scheduleDate' in filters && filters?.scheduleDate && (
            <div
              className={cn(styles.datePickerWrapper, {
                [styles.datePickerWrapperOpen]: datePickerVisible,
              })}
            >
              {/*TODO: hide until TimeZoneFilter feature will be ready*/}
              {/*<TimeZoneFilter />*/}
              <DateFilter
                availableRange={filters?.scheduleDate}
                datePickerVisible={datePickerVisible}
                setDatePickerVisible={setDatePickerVisible}
                inModal={inModal}
              />
            </div>
          )}
        </>

        <div
          className={cn(styles.filtersFooter, { [styles.inModal]: inModal })}
        >
          {!allEmpty && !noClear && (
            <Button
              variant="secondary"
              className={styles.filtersFooterButton}
              onClick={onFilterClear}
            >
              Clear
            </Button>
          )}

          {inModal && (
            <Button
              className={styles.filtersFooterButton}
              onClick={onModalApplyClick}
            >
              Apply
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Filters;
