import { ChangeEvent } from 'react';
// Types
import { GenderInputFilter } from 'api/graphql-global-types';
import { GetMerchProducts_getMerchProducts_filters_genderV2 } from 'api/merch/types/GetMerchProducts';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Components
import CheckboxFilter from '../CheckboxFilter/CheckboxFilter';

type GenderFilterProps = {
  availableOptions: GetMerchProducts_getMerchProducts_filters_genderV2[];
};

export const GenderFilter = ({ availableOptions }: GenderFilterProps) => {
  const { filters, setFilters } = useFiltersContext();

  const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name: GenderInputFilter = e?.target?.name as GenderInputFilter;

    const genderV2: GenderInputFilter[] = filters?.genderV2?.includes(name)
      ? filters?.genderV2?.filter((i) => i !== name) || []
      : [...(filters?.genderV2 || []), name];

    setFilters({ ...filters, genderV2 });
  };
  const isFilterOpen = Boolean(filters?.genderV2?.length);

  return (
    <CheckboxFilter<GetMerchProducts_getMerchProducts_filters_genderV2>
      title="Gender"
      options={availableOptions}
      selectedValues={filters?.genderV2 || []}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.name}
      onChange={onCheckBoxChange}
      isOpen={isFilterOpen}
    />
  );
};

export default GenderFilter;
