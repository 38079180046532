import { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// ui2
import Slider from 'ui3/Slider/Slider';
// Components
import FilterItem from '../FilterItem/FilterItem';

type ResponseDayFilterProps = {
  availableRange: number[];
};

const ResponseDayFilter = ({ availableRange }: ResponseDayFilterProps) => {
  const { filters, setFilters } = useFiltersContext();
  const maxAvailable = Math.round(availableRange[1] || 0);
  const maxSelected = !filters?.daysToResponse?.length
    ? maxAvailable
    : Number(filters?.daysToResponse[1]);
  const [localSliderValue, setLocalSliderValue] = useState(maxSelected);

  const marks = {
    1: '1 day',
    [Number(maxAvailable)]: `${localSliderValue} day${
      Number(localSliderValue) > 1 ? 's' : ''
    }`,
  };

  useEffect(() => {
    setLocalSliderValue(maxSelected);
  }, [maxSelected]);

  const onChangeDebounce = useDebouncedCallback((daysToResponse: number[]) => {
    setFilters({
      ...filters,
      daysToResponse,
    });
  }, 1000);

  const onSliderChange = (maxValue: number) => {
    const daysToResponse =
      Number(maxValue) === maxAvailable ? [] : [1, maxValue];
    setLocalSliderValue(maxValue);

    onChangeDebounce(daysToResponse);
  };

  const isFilterOpen = Boolean(filters?.daysToResponse?.length);

  return (
    <FilterItem title="Response Days" isOpen={isFilterOpen}>
      <Slider
        min={1}
        max={maxAvailable}
        value={localSliderValue}
        defaultValue={maxSelected}
        step={1}
        marks={marks}
        onChange={onSliderChange}
      />
    </FilterItem>
  );
};

export default ResponseDayFilter;
