import { ChangeEvent } from 'react';
// Hooks
import { useGetSports } from 'hooks';
// Types
import { GetStores_stores_filters_sports } from 'api/store/types/GetStores';
import { GetMemorabilia_getMemorabilia_filters_sports } from 'api/memorabilia/types/GetMemorabilia';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Components
import CheckboxFilter from '../CheckboxFilter/CheckboxFilter';

type AthleteSportFilterProps = {
  availableOptions: AvailableOption[];
};

type AvailableOption =
  | Omit<GetStores_stores_filters_sports, '__typename'>
  | Omit<GetMemorabilia_getMemorabilia_filters_sports, '__typename'>;

export const AthleteSportFilter = ({
  availableOptions,
}: AthleteSportFilterProps) => {
  const { filters, setFilters } = useFiltersContext();
  const { data } = useGetSports();

  const allSports = data?.sports || [];

  // show only selected sport
  const sportsOptions = filters?.sports?.length
    ? availableOptions?.filter((item) =>
        filters?.sports?.includes(Number(item.id))
      )
    : availableOptions;

  const onCheckBoxChange = ({
    target: { id },
  }: ChangeEvent<HTMLInputElement>) => {
    const sports: number[] = filters?.sports?.includes(Number(id))
      ? filters?.sports?.filter((i) => i !== Number(id)) || []
      : [...(filters?.sports || []), Number(id)];

    // remove tags that are not related to selected sport
    const selectedHashtagsIds = filters.hashtagIds;
    const selectedSportsWithHashtags = allSports.filter((item) =>
      sports.includes(item.id)
    );
    const hashtagIdsOfSelectedSports = selectedHashtagsIds.filter(
      (selectedItem) =>
        selectedSportsWithHashtags.some((sportItem) =>
          sportItem.hashtags?.find((tag) => tag.id === selectedItem)
        )
    );

    setFilters({
      ...filters,
      sports,
      hashtagIds: hashtagIdsOfSelectedSports,
    });
  };

  const sortedSportsOptions = sportsOptions.slice().sort((a, b) => {
    const nameA = a?.name?.toLowerCase() || '';
    const nameB = b?.name?.toLowerCase() || '';

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  const isFilterOpen = Boolean(filters?.sports?.length);

  return (
    <CheckboxFilter<AvailableOption>
      title="Sport"
      options={sortedSportsOptions}
      selectedValues={filters?.sports || []}
      onChange={onCheckBoxChange}
      getOptionValue={(athlete) => String(athlete.id)}
      getOptionLabel={(sport) => sport.name ?? 'Empty sport name'}
      isOpen={isFilterOpen}
    />
  );
};

export default AthleteSportFilter;
