import { ChangeEvent, useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
// Context
import { useFiltersContext } from 'components/common3/PageWithFiltersWrapper/PageWithFiltersContext';
// Ui2
import Range from 'ui3/Range/Range';
// Components
import Input from './components/Input/Input';
import FilterItem from '../FilterItem/FilterItem';
// Styles
import styles from './PriceFilter.module.scss';

type PriceFilterProps = {
  availableRange: number[];
};

const PriceFilter = ({ availableRange }: PriceFilterProps) => {
  const { filters, setFilters } = useFiltersContext();
  const minAvailable = Math.floor(availableRange[0] || 0);
  const maxAvailable = Math.round(availableRange[1] || 0);
  const minSelected = !filters?.priceRange?.length
    ? minAvailable
    : Number(filters?.priceRange[0]);
  const maxSelected = !filters?.priceRange?.length
    ? maxAvailable
    : Number(filters?.priceRange[1]);
  const [localMinValue, setLocalMinValue] = useState(minSelected);
  const [localMaxValue, setLocalMaxValue] = useState(maxSelected);

  useEffect(() => {
    setLocalMinValue(minSelected);
    setLocalMaxValue(maxSelected);
  }, [minSelected, maxSelected]);

  const onChangeDebounce = useDebouncedCallback((priceRange: number[]) => {
    setFilters({
      ...filters,
      priceRange,
    });
  }, 1000);

  const onRangeChange = (range: number[]) => {
    const priceRange =
      Number(range[0]) === minAvailable && Number(range[1]) === maxAvailable
        ? []
        : range;
    setLocalMinValue(range[0]);
    setLocalMaxValue(range[1]);

    onChangeDebounce(priceRange);
  };

  const onMinPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(e.target.value);
    if (inputValue < minAvailable) {
      onRangeChange([minAvailable, maxSelected]);
    } else if (inputValue > maxSelected) {
      onRangeChange([maxSelected - 1, maxSelected]);
    } else {
      onRangeChange([inputValue, maxSelected]);
    }
  };

  const onMaxPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(e.target.value);
    if (inputValue > maxAvailable) {
      onRangeChange([minSelected, maxAvailable]);
    } else if (inputValue < minSelected) {
      onRangeChange([minSelected, minSelected + 1]);
    } else {
      onRangeChange([minSelected, inputValue]);
    }
  };

  const isFilterOpen = !!filters?.priceRange.length;

  if (!minAvailable && !maxAvailable) {
    return null;
  }

  return (
    <FilterItem
      title="Price"
      className={styles.priceContainer}
      isOpen={isFilterOpen}
    >
      <div className={styles.filterSliderHeader}>
        <div className={styles.prefixInputWrapper}>
          <span className={styles.prefix}>
            {localMinValue > 0 ? '$' : '$0'}
          </span>
          <Input
            className={styles.filterSliderHeaderInput}
            name="priceMin"
            value={localMinValue || ''}
            onChange={onMinPriceChange}
          />
        </div>
        <div className={styles.prefixInputWrapper}>
          <span className={styles.prefix}>$</span>
          <Input
            className={styles.filterSliderHeaderInput}
            name="priceMax"
            value={localMaxValue || ''}
            onChange={onMaxPriceChange}
          />
        </div>
      </div>

      <Range
        min={minAvailable}
        max={maxAvailable}
        value={[localMinValue, localMaxValue]}
        defaultValue={[minAvailable, maxAvailable]}
        step={1}
        onChange={onRangeChange}
      />
    </FilterItem>
  );
};

export default PriceFilter;
