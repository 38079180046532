// Ui
import CheckBox from 'ui3/CheckBox/CheckBox';
import InfiniteScrollLoader from 'ui3/InfiniteScrollLoader/InfiniteScrollLoader';
// Components
import FilterItem from '../FilterItem/FilterItem';
import InfiniteScroll from 'components/common3/InfiniteScroll/InfiniteScroll';
// Styles
import styles from './CheckboxFilter.module.scss';

export interface InfiniteScrollProps {
  dataLength: number;
  next: () => void;
  hasMore: boolean;
  height: number | string;
  loader?: React.ReactNode;
  className?: string;
}

export interface CheckboxFilterProps<T> {
  title: string;
  options: T[];
  selectedValues?: (string | number)[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  getOptionLabel: (option: T) => string | null | undefined;
  getOptionValue: (option: T) => string | null | undefined;
  className?: string;
  infiniteScroll?: InfiniteScrollProps;
  getIfChecked?: (item: T) => boolean;
  isOpen?: boolean;
}

/**
 * A generic checkbox filter component.
 * It renders a FilterItem wrapper containing a list of CheckBox components.
 */
const CheckboxFilter = <T,>({
  title,
  options,
  selectedValues,
  onChange,
  getOptionLabel,
  getOptionValue,
  className,
  infiniteScroll,
  getIfChecked,
  isOpen = false,
}: CheckboxFilterProps<T>) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  //  Checkbox value will always be string, but we can have numbers here
  const convertedSelectedValues = selectedValues?.map((i) => String(i));

  const listContent = (
    <ul className={styles.filterList}>
      {options.map((option, index) => {
        const value = getOptionValue(option) ?? '';
        const label = getOptionLabel(option) ?? '';
        const isChecked = Boolean(
          value &&
            (convertedSelectedValues?.includes(value) ?? true) &&
            (getIfChecked?.(option) ?? true)
        );

        return (
          <li key={`${index}_${value}`}>
            <CheckBox
              className={styles.filterListItemCheckbox}
              name={value}
              label={label}
              labelClassName={styles.filterListItemCheckboxLabel}
              onChange={handleChange}
              checked={isChecked}
            />
          </li>
        );
      })}
    </ul>
  );

  return (
    <FilterItem title={title} className={className} isOpen={isOpen}>
      {infiniteScroll ? (
        <InfiniteScroll
          className={infiniteScroll.className}
          dataLength={infiniteScroll.dataLength}
          next={infiniteScroll.next}
          hasMore={infiniteScroll.hasMore}
          height={infiniteScroll.height}
          loader={infiniteScroll.loader ?? <InfiniteScrollLoader />}
        >
          {listContent}
        </InfiniteScroll>
      ) : (
        listContent
      )}
    </FilterItem>
  );
};

export default CheckboxFilter;
